//?--------------------------------------------------------------------

//? https://swiperjs.com/get-started

//?--------------------------------------------------------------------
// // import Swiper JS
// import Swiper from "swiper";
// // import Swiper styles
// import "swiper/css";

//?--------------------------------------------------------------------
// // core version + navigation, pagination modules:
// import Swiper, { Navigation, Pagination } from "swiper";
// // import Swiper and modules styles
// import "swiper/css";
// import "swiper/css/navigation";
// import "swiper/css/pagination";

//?--------------------------------------------------------------------
// import Swiper bundle with all modules installed
import Swiper from "swiper/bundle";
// import styles bundle
import "swiper/css/bundle";

require("../scss/swiper/swiper-front-page.scss");

// document.addEventListener("DOMContentLoaded", function () {

//
const swiper = new Swiper("#font-page-swiper", {
  speed: 400,
  slidesPerView: 1,
  spaceBetween: 24,
  // Responsive breakpoints
  breakpoints: {
    // when window width is >= 480px
    576: {
      slidesPerView: 2,
      // spaceBetween: 30
    },
    768: {
      slidesPerView: 3,
      // spaceBetween: 40
    },
    992: {
      slidesPerView: 4,
      // spaceBetween: 40
    },
  },
  edgeSwipeDetection: "prevent",

  // And if we need scrollbar
  scrollbar: {
    // draggable: true,
    enabled: false,
  },

  // Navigation arrows
  navigation: {
    // enabled: true,
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },

  // If we need pagination
  pagination: {
    el: ".swiper-pagination",
    type: "bullets",
  },

  // loop: true,
  // centerInsufficientSlides: false, // Default; false
  // centeredSlides: false, // Default; false
  // centeredSlidesBounds: false, // Default; false
  //
  // effect: "coverflow",
  // grabCursor: true,
  // centeredSlides: true,
  // slidesPerView: "auto",
  // coverflowEffect: {
  //   rotate: 0,
  //   stretch: 0,
  //   depth: 0,
  //   modifier: 0,
  //   slideShadows: false,
  // },

  //
});

//
// });
